<template>
  <div
    :style="{ height: containerHeight }"
    class="container d-flex align-items-center"
  >
    <div class="w-100 text-center">
      <Spline :scene="scene" class="spline_logo" />
      <div class="intro_title">
        We <span ref="typedElement" class="gr"></span>
      </div>
    </div>
  </div>
  <div class="bottom_comp">
    <p class="mb-0">BYTEWAVE IT Services LLC © 2023</p>
  </div>
</template>

<script>
import Typed from "typed.js";
import Spline from "spline-vue/v3";
export default {
  components: {
    Spline,
  },
  data() {
    return {
      typedElement: null,
      containerHeight: "",
      typed: null,
      scene: "../spline/scene.splinecode",
    };
  },
  methods: {
    updateContainerHeight() {
      if (typeof window !== "undefined") {
        this.containerHeight = window.innerHeight + "px";
      }
    },
  },
  mounted() {
    this.typedElement = this.$refs.typedElement;

    if (this.typedElement) {
      this.typed = new Typed(this.typedElement, {
        strings: ["create", "change", "introduce", "build", "promote"],
        typeSpeed: 50,
        loop: true,
        backSpeed: 50,
      });
    }

    this.updateContainerHeight();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.updateContainerHeight);
    }
  },
  beforeUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateContainerHeight);
    }

    if (this.typed) {
      this.typed.destroy();
    }
  },
};
</script>
