<template>
  <HomePath />
</template>

<script>
import HomePath from "@/components/HomePath.vue";
export default {
  components: {
    HomePath,
  },
};
</script>
